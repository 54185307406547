<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="a" x1="0.647" x2="0.228" y1="-0.056" y2="0.89">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g transform="translate(-370 -688)">
    <rect :fill="background" height="60" transform="translate(370 688)" width="60"/>
    <path d="M16.184,56.78A3.223,3.223,0,1,1,12.962,60,3.218,3.218,0,0,1,16.184,56.78ZM27,56.78A3.223,3.223,0,1,1,23.775,60,3.218,3.218,0,0,1,27,56.78ZM1.074,33.148H5.557L10.742,51.3l-.408.816A3.221,3.221,0,0,0,13.22,56.78H31.294a1.074,1.074,0,1,0,0-2.148H13.212a1.074,1.074,0,0,1-.96-1.554l.294-.594H31.294a1.066,1.066,0,0,0,1.031-.781l4.3-15.038a1.074,1.074,0,0,0-1.031-1.375H8.4l-1-3.516A1.074,1.074,0,0,0,6.366,31H1.074a1.074,1.074,0,1,0,0,2.148Z" fill="url(#a)" transform="translate(378.668 671.887)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>